.full-width-upload .ant-upload {
  width: 100%;
}

.full-width-upload .ant-upload-btn {
  width: 100%;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #7f56d9;
  border-color: #7f56d9;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #d9d9d9 !important;
}
