.blogs {
  .card-title {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .author-title {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .description {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .card-shadow {
    // box-shadow: 3px -3px 6px -2px #10182808;
    // box-shadow: 12px 12px 16px 20px #10182814;

    box-shadow: 0px 0px 20px 5px #10182814;
  }
}
