// .blog-post {
//   display: flex;
//   flex-wrap: wrap; /* Allow content to wrap if it overflows */
// }

// .blog-post-image {
//   flex: 1 0 30%; /* Set width to 30% of available space */
//   margin: 10px;
// }

// .blog-post-content {
//   flex: 2 1 60%; /* Set width to 60% of available space */
//   margin: 10px;
// }

.content {
  padding: 20px;
}
.mysection {
  display: block;
}
.mysection img {
  width: 48%;
  margin: 0 0 40px 40px;
  float: right;
  margin-right: 10px;
  background-color: lightblue;
  border-radius: 12px;
}

.card-shadow {
  box-shadow: 0px 0px 20px 5px #10182814;
}

@media (max-width: 1024px) {
  .mysection {
    display: flex;
    flex-direction: column;
  }
  .mysection img {
    margin: 0 !important;
    width: 100%;
  }
  .content {
    padding: 20px 0;
  }
}
