@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
}
.markdown > * {
  all: revert;
}
