.activeScenario {
  border: 2px solid #7f56d9;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  transition:
    box-shadow 0.3s ease-in-out,
    border 0.3s ease-in-out;
}
.nonActiveScenario {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  transition:
    box-shadow 0.3s ease-in-out,
    border 0.3s ease-in-out;
  border: 2px solid transparent;
}
.performingValues {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}
.outcomes-border {
  border-right: 2px dashed #475467;
}

@media (max-width: 768px) {
  .outcomes-border {
    border-right: 0px;
    border-bottom: 2px dashed #475467;
  }
}
