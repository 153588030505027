.privary-policy {
  .ant-collapse {
    background-color: #f3f2f2 !important;
    .ant-collapse-item {
      padding: 10px 0;
      background-color: white !important;
      margin-bottom: 30px !important;
      border: 1px solid transparent !important;
      transition: border 0.5s ease-in-out;
      border-radius: 15px !important;
      .ant-collapse-header {
        display: flex !important;
        align-items: center !important;
      }
      .ant-collapse-header-text {
        font-size: 23px !important;
        font-weight: 600 !important;
        color: #170f49 !important;
      }
    }
    .ant-collapse-item-active {
      border: 1px solid #6941c6 !important;
      transition: border 0.3s ease-in-out !important;
    }
  }
  .iconShadow {
    box-shadow: 0px 5px 7px 4px #080f340f;
  }
}

@media (max-width: 545px) {
  .privary-policy {
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header-text {
          font-size: 16px !important;
          font-weight: 600 !important;
          color: #170f49 !important;
        }
      }
    }
  }
}
