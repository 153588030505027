.react-multi-carousel-list .react-multi-carousel-arrow {
  display: none;
}
.carousel-item-gap {
  padding-right: 1rem;
}
.cont-shadow {
  box-shadow: 0px 4px 12px 0px #00000029;
}
.plan-shadow {
  box-shadow: 0px 4px 12px 0px #00000029;
}
