.createdPerTable {
  table {
    width: auto;
    min-width: unset !important;
  }
}
.ant-modal-title {
  text-align: center !important;
  font-size: 24px !important;
  font-weight: 600 !important;
}
